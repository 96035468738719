import { mapGetters } from "vuex";

export default {
   methods: {
      hasPermissions(permissions) {
         const permissionArray = permissions.filter((permission) =>
            this.getPermissions.includes(permission)
         );
         return permissionArray.length > 0 || this.isSuperAdmin;
      },
   },
   computed: {
      ...mapGetters("auth", ["getRoles", "getPermissions"]),
      isSuperAdmin() {
         return this.getRoles.includes("superadmin");
      },
   },
};
