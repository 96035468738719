<template>
   <div class="left-side-menu">
      <div class="h-100" data-simplebar>
         <!--- Sidemenu -->
         <div id="sidebar-menu">
            <ul id="side-menu">
               <li class="menu-title mt-2">Meeting Management</li>

               <li v-if="hasPermissions(['room-list'])">
                  <router-link class="nav-link" :to="{ name: 'rooms' }">
                     <i class="fe-airplay"></i>
                     <span>Room </span>
                  </router-link>
               </li>

               <li v-if="hasPermissions(['meeting-list'])">
                  <router-link class="nav-link" :to="{ name: 'meetings' }">
                     <i class="fe-clipboard"></i>
                     <span>All Meetings </span>
                  </router-link>
               </li>

               <li
                  class="menu-title mt-2"
                  v-if="
                     hasPermissions([
                        'role-list',
                        'permission-list',
                        'user-list',
                     ])
                  "
               >
                  Administration
               </li>

               <li
                  v-if="
                     hasPermissions([
                        'role-list',
                        'permission-list',
                        'user-list',
                     ])
                  "
               >
                  <a href="#user-management" data-bs-toggle="collapse">
                     <i class="fe-users"></i>
                     <span> User Management </span>
                     <span class="menu-arrow"></span>
                  </a>
                  <div class="collapse" id="user-management">
                     <ul class="nav-second-level">
                        <li v-if="hasPermissions(['user-list'])">
                           <router-link class="nav-link" :to="{ name: 'user' }">
                              <span>Users </span>
                           </router-link>
                        </li>

                        <li v-if="hasPermissions(['role-list'])">
                           <router-link
                              class="nav-link"
                              :to="{ name: 'roles' }"
                           >
                              <span>Roles </span>
                           </router-link>
                        </li>

                        <li v-if="hasPermissions(['permission-list'])">
                           <router-link
                              class="nav-link"
                              :to="{ name: 'permissions' }"
                           >
                              <span>Permissions </span>
                           </router-link>
                        </li>
                     </ul>
                  </div>
               </li>
            </ul>
         </div>
         <!-- End Sidebar -->

         <div class="clearfix"></div>
      </div>
      <!-- Sidebar -left -->
   </div>
</template>

<script>
import { mapGetters } from "vuex";
//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
   name: "Sidebar",
   mixins: [userHasPermissions],
   computed: {
      ...mapGetters("auth", ["getAuthName", "getAuthId"]),
   },
};
</script>

<style>
</style>